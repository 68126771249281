const navbar1 = [
    { label: "início", link: "#inicio" },
    { label: "a pousada", link: "#a_pousada" },
    { label: "acomodações", link: "#acomodacoes" },
    { label: "galeria", link: "#galeria" },
    { label: "localização", link: "#localizacao" },
    { label: "reservas e contato", link: "#reservas_contato" },
    { label: "políticas e cancelamento", link: "#politica_cancelamento" },
];

export {
    navbar1
}