const cardList1 = [
    'Suíte mais ampla',
    '01 cama casal Queen Size',
    '01 cama de solteiro extra grande',
    'Móveis rústicos - Banheiro Privativo',
    'TV LCD - DVD - Frigobar',
    'AR Condicionado',
    'Varanda com rede',
    'Pode receber até 03 pessoas'
];

const cardList2 = [
    "01 cama de casal Queen Size",
    "02 camas de solteiro",
    "Tv LCD",
    "Frigobar",
    "AR Condicionado",
    "Banheiro privativo",
    "Varanda com rede",
    "Decoração Rústica",
    "Pode receber até 04 pessoas"
];

const cardList3 = [
    "01 Cama de casal queen size",
    "Ventilador",
    "Frigobar",
    "Tv LCD",
    "Banheiro privativo",
    "Varanda com Rede",
    "Pode receber até 02 pessoas"
];

export {
    cardList1, cardList2, cardList3
}