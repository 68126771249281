import img1 from '../gallery/img1.jpg'
import img2 from '../gallery/img2.jpg'
import img3 from '../gallery/img3.jpg'
import img4 from '../gallery/img4.jpg'
import img5 from '../gallery/img5.jpg'
import img6 from '../gallery/img6.jpg'
import img7 from '../gallery/img7.jpg'
import img8 from '../gallery/img8.jpg'
// import img9 from '../gallery/img9.jpg'
import img10 from '../gallery/img10.jpg'
import img11 from '../gallery/img11.jpg'
import img12 from '../gallery/img12.jpg'
import img13 from '../gallery/img13.jpg'
import img14 from '../gallery/img14.jpg'
import img15 from '../gallery/img15.jpg'
import img16 from '../gallery/img16.jpg'
import img17 from '../gallery/img17.jpg'
import img18 from '../gallery/img18.jpg'
import img19 from '../gallery/img19.jpg'
import img20 from '../gallery/img20.jpg'
import img21 from '../gallery/img21.jpg'
import img22 from '../gallery/img22.jpg'
import img23 from '../gallery/img23.jpg'
import img24 from '../gallery/img24.jpg'
import img25 from '../gallery/img25.jpg'

const images = [
    { original: img1, thumbnail: img1 },
    { original: img2, thumbnail: img2 },
    { original: img3, thumbnail: img3 },
    { original: img4, thumbnail: img4 },
    { original: img5, thumbnail: img5 },
    { original: img6, thumbnail: img6 },
    { original: img7, thumbnail: img7 },
    { original: img8, thumbnail: img8 },
    // { original: img9, thumbnail: img9 },
    { original: img10, thumbnail: img10 },
    { original: img11, thumbnail: img11 },
    { original: img12, thumbnail: img12 },
    { original: img13, thumbnail: img13 },
    { original: img14, thumbnail: img14 },
    { original: img15, thumbnail: img15 },
    { original: img16, thumbnail: img16 },
    { original: img17, thumbnail: img17 },
    { original: img18, thumbnail: img18 },
    { original: img19, thumbnail: img19 },
    { original: img20, thumbnail: img20 },
    { original: img21, thumbnail: img21 },
    { original: img22, thumbnail: img22 },
    { original: img23, thumbnail: img23 },
    { original: img24, thumbnail: img24 },
    { original: img25, thumbnail: img25 },
];

export {
    images
}